<template>
  <div>
    <v-btn
        v-if="!$store.state.customer.signature"
        text
        depressed
        class="text-field-mimic justify-start text-start"
        @click="dialog = true"
    >
      <v-icon color="primary" left>mdi-close</v-icon>
      <span v-if="$vuetify.breakpoint.smAndDown" class="text-primary-italic">Unterschrift tätigen</span>
      <span v-if="$vuetify.breakpoint.smAndUp" class="text-primary-italic">Hier klicken zum Unterschreiben</span>
    </v-btn>


    <!-- Wenn eine Unterschrift vorhanden ist -->
    <v-btn
        v-else
        text
        depressed
        class="signature-button"
        @click="dialog = true"

    >
      <strong class="mr-4">Unterschrift:</strong>
      <v-icon color="primary" left>mdi-close</v-icon>
      <v-img :src="$store.state.customer.signature" class="signature-image"/>
    </v-btn>

    <v-dialog
        persistent
        v-model="dialog"
        width="600"
        max-width="90%"
    >
      <v-card
          class="signature-dialog pa-6"
          elevation="15"
      >
        <v-card-title class="signature-title">
          <v-icon large left color="primary">mdi-pencil</v-icon>
          <h2 class="text-h5 mb-0">Unterschrift des Antragstellers</h2>
        </v-card-title>

        <v-card-text>
          <VueSignaturePad
              class="signature-pad mt-4"
              ref="signaturePad"
              :options="{ onBegin, onEnd }"
          />
        </v-card-text>

        <v-divider class="my-4"></v-divider>

        <v-card-actions>
          <v-btn
              outlined
              color="red darken-2"
              class="ma-2 signature-button"
              @click="undo"
          >
            <v-icon left>mdi-delete</v-icon>
            Löschen
          </v-btn>

          <v-spacer/>

          <v-btn
              color="primary"
              class="ma-2 signature-button"
              @click="onCloseDialog()"
          >
            <v-icon left>mdi-content-save</v-icon>
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PB-Signature",

  data: () => ({
    dialog: false,
  }),

  methods: {
    undo() {
      this.signature = undefined;
      this.$refs.signaturePad.undoSignature();
    },

    onBegin() {
      this.$refs.signaturePad.resizeCanvas()
    },
    onEnd() {
      const {data} = this.$refs.signaturePad.saveSignature();
      this.$set( this.$store.state.customer, "signature",  data)
      // this.$store.state.customer.signature = data;
    },
    onCloseDialog(){
      this.onEnd()
      this.dialog = false
    }
  },
})
</script>


<style scoped>

.signature-dialog {
  background: linear-gradient(45deg, #FFF3E0, #ECEFF1);
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.signature-title {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 15px 15px 0 0;
}

.signature-pad {
  width: 100%;
  height: 400px !important;
  border: 2px solid #a4a4a4;
  border-radius: 5px;
  background-color: #F8F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signature-pad::before {
  content: "Unterschreiben Sie hier mit Ihrem Finger oder Ihrer Maus";
  position: absolute;
  color: #a4a4a4;
}

.text-field-mimic {
  width: 100%;  /* Sie können dies an Ihre Bedürfnisse anpassen */
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);  /* Um das Unterstrich-Aussehen zu imitieren */
}

.signature-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);  /* Um das Unterstrich-Aussehen zu imitieren */
  transition: transform 0.1s;
  border-radius: 50px;
}

.signature-button:hover {
  transform: translateY(-3px);
}

.signature-image {
  width: 100px;  /* Stellt sicher, dass das Bild nicht zu groß wird */
  height: auto;  /* Behält das Seitenverhältnis bei */

}

.text-primary-italic {
  color: var(--v-primary-base);
  font-style: italic;
}


</style>