<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="primary--text">Impressum</h1>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Verantwortlich</h2>
        <p>Daniel Gottschalk<br>
          Gervinusstr. 21<br>
          10629 Berlin</p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Kontakt</h2>
        <p>Tel.: 030 - 327 04 161<br>
          E-Mail: <a href="mailto:info@pflegemittelhilfe.de">info@pflegemittelhilfe.de</a></p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Umsatzsteuer-ID</h2>
        <p>DE 190835834</p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Berufshaftpflichtversicherung: </h2>
        <p>R+V Allgemeine Versicherung AG<br>
          Niedersachsenring 13<br>
          30163 Hannover<br>
          Geltungsbereich: Deutschland</p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">IK Nummer: </h2>
        <p>331109159</p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Haftungsbeschränkung für eigene Inhalte</h2>
        <p>Die Inhalte unserer Webseiten wurden sorgfältig und nach bestem Gewissen erstellt. Gleichwohl kann für die Aktualität, Vollständigkeit und Richtigkeit sämtlicher Seiten keine Gewähr übernommen werden. Gemäß § 7 Abs. 1 TMG sind wir als Diensteanbieter für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Wir sind als Diensteanbieter nach den §§ 8 bis 10 TMG jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen. Ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung erfolgt eine umgehende Entfernung dieser Inhalte durch uns. Eine diesbezügliche Haftung kann erst ab dem Zeitpunkt der Kenntniserlangung übernommen werden.</p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Haftungsbeschränkung für externe Links</h2>
        <p>Unsere Webseite enthält Verknüpfungen zu Webseiten Dritter (sog. „externe Links“). Da wir auf deren Inhalte keinen Einfluss haben, kann für die fremden Inhalte keine Gewähr übernommen werden. Für die Inhalte und Richtigkeit der Informationen ist stets der jeweilige Informationsanbieter der verlinkten Webseite verantwortlich. Zum Zeitpunkt der Verlinkung waren keine Rechtsverstöße erkennbar. Sobald uns eine Rechtsverletzung bekannt wird, werden wir den jeweiligen Link umgehend entfernen.</p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Urheberrecht</h2>
        <p>Die auf dieser Webseite veröffentlichten Inhalte und Werke unterliegen dem deutschen Urheberrecht. Jede Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der vorherigen schriftlichen Zustimmung des jeweiligen Urhebers bzw. Autors.</p>
      </v-col>

      <v-col cols="12">
        <h2 class="primary--text">Plattform der EU-Kommission zur Online-Streitbeilegung:</h2>
        <p><a href="https://www.ec.europa.eu/consumers/odr">https://www.ec.europa.eu/consumers/odr</a>

          Zur Teilnahme an einem Streitbeteiligungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und auch nicht bereit:</p>
      </v-col>




      <!-- Weitere Abschnitte hier einfügen -->

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "impressum"
}
</script>

<style scoped>

</style>