import '@mdi/font/css/materialdesignicons.min.css'
import Vue from 'vue'
import App from './App.vue'
import store from './system/store'
import router from "./system/router";
import vuetify from './system/vuetify'
import "./registerServiceWorker";
import FlagIcon from "vue-flag-icon";
import VueSignaturePad from "vue-signature-pad";
import VueObserveVisibility from 'vue-observe-visibility';



Vue.use(FlagIcon);
Vue.use(VueSignaturePad);
Vue.use(VueObserveVisibility);


router.beforeEach((to, from, next) => {
  if (
    to.path === '/pflegegrad' ||
    to.path === '/' ||
    to.path.includes("/unterschrift/") ||
    to.path.includes("/pflegegrad/") ||
    to.path.includes("/pflegegrad_bestimmen/") ||
    to.path.includes("/versicherung/") ||
    to.path.includes("/pflegebox/")
  ) {
    next()
  } else {
    if (store.state.system.selectedProducts.length === 0) {
      next()
    } else {
      // Wenn der Store nicht leer ist, lasse die Navigation zu
      next()
    }
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  beforeCreate() {
    this.$store.dispatch('initialiseStore').then();
  },
  render: h => h(App)
}).$mount('#app')
