<template>
  <div class="mt-10 mb-8">
    <v-row class="d-flex justify-center align-center" v-if="step === '1'">
      <v-col cols="2" class="step-container">
        <img
            src="/static/icons/Choose-product.png"
            class="mr-2 primary--text"
            alt="Choose-product Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container">
        <img
            src="/static/icons/Data-entry.png"
            class="mr-2 primary--text"
            alt="Data-entry Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container">
        <img
            src="/static/icons/Signature.png"
            class="mr-2 primary--text"
            alt="Signature Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container">
        <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">Produkte wählen</div>
    </v-row>


    <v-row class="d-flex justify-center align-center" v-if="step === '2'">
      <v-col cols="2" class="step-container">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="dividerFinish primary--text"></v-col>
      <v-col cols="2" class="step-container">
         <img
            src="/static/icons/Data-entry.png"
            class="mr-2 primary--text"
            alt="Data-entry Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container">
         <img
            src="/static/icons/Signature.png"
            class="mr-2 primary--text"
            alt="Signature Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container">
         <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">Dateneingabe</div>
    </v-row>


    <v-row class="d-flex justify-center align-center" v-if="step === '3'">
      <v-col cols="2" class="step-container">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="dividerFinish primary--text"></v-col>
      <v-col cols="2" class="step-container">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="dividerFinish"></v-col>
      <v-col cols="2" class="step-container">
         <img
            src="/static/icons/Signature.png"
            class="mr-2 primary--text"
            alt="Signature Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="divider"></v-col>
      <v-col cols="2" class="step-container">
         <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 gray--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">Unterschrift</div>
    </v-row>


    <v-row class="d-flex justify-center align-center" v-if="step === '4'">
      <v-col cols="2" class="step-container">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Produktauswahl</div>
      </v-col>
      <v-col cols="1" class="dividerFinish primary--text"></v-col>
      <v-col cols="2" class="step-container">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Dateneingabe</div>
      </v-col>
      <v-col cols="1" class="dividerFinish"></v-col>
      <v-col cols="2" class="step-container">
        <v-icon class="primary--text" x-large>mdi-check-circle-outline</v-icon>
        <div class="mt-2 primary--text hidden-xs-only">Unterschrift</div>
      </v-col>
      <v-col cols="1" class="dividerFinish"></v-col>
      <v-col cols="2" class="step-container">
        <img
            src="/static/icons/Done.png"
            class="mr-2 primary--text"
            alt="Done Icon"
            width="45"
        />
        <div class="mt-2 primary--text hidden-xs-only">Fertig</div>
      </v-col>
      <div class="mt-2 primary--text sm:block md:block text-center hidden-sm-and-up">Fertig</div>
    </v-row>


  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PB-Stepper",

  props: {
    step: String
  },

})
</script>



<style scoped>
.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding-left: 0px;
  padding-right: 0px;
}

.divider {
  border-top: 3px solid #aaa;
  margin: 0 15px;
  width: 30px;
}

.dividerFinish {
  border-top: 3px solid var(--v-primary-base) !important;
  margin: 0 15px;
  width: 30px;
}

@media (max-width: 1264px) { /* 'sm' and 'md' devices */
  .divider {
    border-top: 2px solid #aaa;
    margin: 0 5px;
    width: 10px;
    padding-top: 0;
  }
  .dividerFinish {
    border-top: 2px solid var(--v-primary-base) !important;
    margin: 0 5px;
    width: 10px;
    padding-top: 0;
  }
}
</style>