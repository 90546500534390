<template>
  <div>

    <div class="content-container3">

      <!-- Fortschrittsbalken -->
      <div class="progress-container">
        <div class="progress-bar"></div>
      </div>

      <!-- Text -->
      <div class="d-flex align-center justify-center mt-4 mb-2 no-wrap">
        <h2 class="primary-text align-center  text-center text-h4">Wie sind Sie versichert?</h2>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" color="primary" v-bind="attrs" v-on="on" @click="dialog = true">mdi-information</v-icon>
          </template>
          <span>Warum fragen wir dies?</span>
        </v-tooltip>
      </div>




      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>Kostenübernahme mit Ihrer Pflegekasse</v-card-title>
          <v-card-text>

          Die Beantragung und Abwicklung der Kostenübernahme mit Ihrer Pflegekasse übernimmt das Team der Pflegemittelhilfe für Sie. Für gesetzliche Versicherte entstehen keinerlei Kosten.<br><br>

          Privatpatienten gehen in Vorleistung und beantragen die Erstattung der Kosten der Pflegehilfsmittel bei Ihrer Pflegekasse selbst. Sie haben nach § 40 Abs. 3 Satz 5 SGB XI einen Anspruch auf volle Rückerstattung.
        </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = false">Verstanden</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="d-flex justify-space-between align-center w-100">
        <v-btn
            class="care-btn elevation-0 mr-4"
            @mouseover="elevateButton"
            @mouseleave="deElevateButton"
            @click="insuranceTypClick1"
            large
            color="gradient-primary"
            :style="isMobile ? 'width: 45%; height: 150px; font-size: 20px' : 'width: 50%; height: 250px; font-size: 25px;' ">
          <div class="d-flex flex-column align-center justify-center" style="width: 100%; height: 100%;">
            <img class="icon-img" src="https://www.docdorado.de/media/0a/c2/78/1695590536/gericht-100.png" alt="Gesetzlich" />
            <span class="mt-4 white--text">Gesetzlich</span>
          </div>
        </v-btn>
        <v-btn
            class="care-btn elevation-0 ml-4"
            @mouseover="elevateButton"
            @mouseleave="deElevateButton"
            @click="insuranceTypClick2"
            large
            color="gradient-primary"
            :style="isMobile ? 'width: 45%; height: 150px; font-size: 20px; margin' : 'width: 50%; height: 250px; font-size: 25px;' ">

          <div class="d-flex flex-column align-center justify-center" style="width: 100%; height: 100%;">
            <img class="icon-img" src="https://www.docdorado.de/media/e6/75/e2/1695590536/gebäude-80.png" alt="Privat" />
            <span class="mt-4 white--text">Privat</span>
          </div>
        </v-btn>
      </div>
  </div>


    <div class="testimonials-container3">
      <div v-for="(testimonial, index) in testimonials" :key="index" class="testimonial-item3">
        <div class="rating-container3">
          <v-icon v-for="star in testimonial.rating" :key="star" color="primary" class="rating-icon">mdi-star</v-icon>
        </div>
        <p class="testimonial-text3">{{ testimonial.text }}</p>
      </div>
    </div>

  </div>

</template>

<script>

import {mapFields} from "vuex-map-fields";

export default {
  methods: {
    checkMobile()
    {
      this.isMobile = window.innerWidth <= 768;
    },
    elevateButton(event) {
      event.currentTarget.classList.remove('elevation-0');
      event.currentTarget.classList.add('elevation-5');
    },
    deElevateButton(event) {
      event.currentTarget.classList.remove('elevation-5');
      event.currentTarget.classList.add('elevation-0');
    },
    insuranceTypClick1() {
      this.$router.push('/pflegebox');
      this.setInsuranceType(1);
      this.$vuetify.goTo(0).then()
    },
    insuranceTypClick2() {
      this.$router.push('/pflegebox');
      this.setInsuranceType(2);
      this.$vuetify.goTo(0).then()
    },
    setInsuranceType(insuranceType) {
      this.$store.commit('setInsuranceType', insuranceType);
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile();
  },
  computed: {
    ...mapFields([
      'customer.insuranceType',
    ]),
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      dialog: false,
      testimonials: [
        { rating: 5, text: "Wir beziehen die Pflegebox für unsere Großmutter schon seit einiger Zeit vom Pflegemittelhilfe und sind äußerst zufrieden. Auch der Wechsel war einfach und unkompliziert. Alles wunderbar!" },
        { rating: 5, text: "Die Lieferungen vom Pflegemittelhilfe sind immer pünktlich und Anpassungen an der Pflegebox sind kinderleicht. Macht weiter so, liebes Team!" },
        { rating: 4, text: "Die Bestellung und Lieferung der Pflegebox durch das Pflegemittelhilfe war reibungslos. Bei Fragen stand mir der Kundenservice stets zur Seite. Gerne wieder!" },
        { rating: 5, text: "Das Angebot des Pflegemittelhilfe hat meiner Großmutter im Alltag enorm geholfen. Es erleichterte uns die Entscheidung, sie zu Hause zu betreuen." },
      ],
      // falls Sie hier noch andere Daten haben, fügen Sie sie hinzu...
    };
  },
}
</script>

<style>

.content-container3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 53vh; /* Nehmen Sie die volle Höhe des Viewports */
  padding: 0 15px; /* Einige zusätzliche Polsterung an den Seiten, falls gewünscht */
}



.progress-container {
  width: 40%;
  height: 20px;
  background-color: #f0f0f0; /* Hintergrundfarbe des Fortschrittsbalkens */
}

.gradient-primary {
  background: linear-gradient(315deg, #307470, #307470 80%);
  color: white; /* Setzen Sie eine Farbe für den Text, die gut mit dem Gradienten harmoniert */
}

.progress-bar {
  width: 60%; /* 2 von 5 Schritten -> 40% */
  height: 100%;
  background-color: var(--v-primary-base); /* Primärfarbe des Fortschrittsbalkens */
}

.icon-img {
  width: 80px; /* Die gewünschte Bildgröße, hier z.B. 80px */
  height: 80px;
}

.testimonials-container3 {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  margin-bottom: 50px;
}

.testimonial-item3 {
  width: 23%; /* 100% geteilt durch 4 minus Abstand */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-icon {
  font-size: 24px;
}

.rating-number {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}

.testimonial-text3 {
  font-size: 16px;
  text-align: center;
}

.rating-container3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.rating-icon {
  font-size: 24px;
  margin-right: 5px; /* Abstand zwischen den Sternen */
}

/* Für Mobilgeräte */
@media (max-width: 900px) {

  content-container3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 45vh;
    padding: 0 15px;
  }

  .testimonials-container3 {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-item3 {
    width: 100%; /* Nehmen Sie die volle Breite auf mobilen Geräten */
    margin-bottom: 20px; /* Ein Abstand zwischen den Testimonials */
    padding: 0 10px 0 10px;
  }
}


</style>

