<template>
  <div>

<!--    Header Text-->
    <v-container>
      <v-row >
        <v-col class="my-4" cols="12">
          <h1 v-if="!isMobile" class="primary--text text-h3 font-weight-bold text-center">Pflegehilfsmittel im Wert von 40€ pro Monat GRATIS sichern</h1>
          <h1 v-else class="primary--text text-h4 font-weight-bold text-center">Gratis Pflegehilfsmittel im Wert von <br/>40€ / Monat</h1>
        </v-col>

        <v-col cols="12">
          <div v-if="!isMobile" class="text-center text-h5">
            Sie haben einen Pflegegrad? Dann sichern Sie sich jetzt Ihren Anspruch auf <strong> kostenlose Pflegehilfsmittel </strong>. Wir übernehmen den kompletten Antragsprozess und klären alles Weitere mit Ihrer Pflegekasse.
          </div>
          <div v-else class="text-center text-h5">
            Sie haben einen Pflegegrad? Dann sichern Sie sich jetzt Ihren Anspruch auf <strong> kostenlose Pflegehilfsmittel </strong> in unter 3 Minuten
            <v-icon color="black" large>mdi-clock-fast</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col>
          <v-row :class="isMobile ? 'mt-0 mb-0' : 'mt-8 mb-16'" class="Justify-center align-center">
            <v-col cols="12" lg="6" md="6">
              <v-row >
                <v-col cols="12" class="justify-center text-center align-center">
                  <h2 v-if="isMobile" id="pflegegrad" class="primary-text mb-0 text-h5 font-weight-bold">Haben Sie einen Pflegegrad?</h2>
                  <h2 v-else id="pflegegrad" class="primary-text mb-4 text-h4 font-weight-bold">Haben Sie einen Pflegegrad?</h2>
                </v-col>

                <v-col cols="6" >
                  <v-card color="background" class="ma-2 h-auto square-card" v-ripple>
                    <v-row class="d-flex justify-center align-center fill-height" no-gutters @click="setCareDegreeAndNavigate"    @mouseover="elevateButton"
                           @mouseleave="deElevateButton" style="cursor: pointer">
                      <v-col cols="12" class="text-center" >
                        <div>
                          <div>
                            <v-icon color="white" size="75">
                              mdi-thumb-up
                            </v-icon>
                          </div>
                          <div class="text-h5 mt-2 white--text">
                            Ja
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card color="background" class="ma-2 h-auto square-card" v-ripple>
                    <v-row class="d-flex justify-center align-center fill-height" no-gutters @click="showModal = true"  @mouseover="elevateButton"
                           @mouseleave="deElevateButton" style="cursor: pointer">
                      <v-col cols="12" class="text-center">
                        <div>
                          <div>
                            <v-icon color="white" size="75">
                              mdi-thumb-down
                            </v-icon>
                          </div>
                          <div class="text-h5 mt-2 white--text">
                            Nein
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" lg="6" md="6">
              <v-img
                  :src="imageSource"
                  :class="{ 'mobile-image': isMobile }"
                  :aspect-ratio="calculatedAspectRatio"
                  contain
              ></v-img>
            </v-col>

            <v-col cols="12">
              <div class="usps">
                <v-row class="align-center text-center">
                  <v-col v-for="usp in usps" :key="usp.text" cols="6" md="6" >
                    <div class="mb-4">
                      <v-icon class="usp-icon" color="primary" :size="iconSize">{{ usp.icon }}</v-icon>
                    </div>
                    <div >{{ usp.text }}</div>
                    <div >{{ usp.subtext }}</div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-row style="background-color: #9ae17b">
      <v-container class="py-16">
        <v-row class="text-center justify-center">
          <v-col :cols="isMobile ? 12 : 3" v-for="(testimonial, index) in testimonials" :key="index">
            <div>
              <div class="mb-4">
                <v-icon v-for="star in 5" :key="star" :color="star <= testimonial.rating ? 'homeButton' : 'grey'">mdi-star</v-icon>
              </div>
              <p class="white--text font-italic">{{ testimonial.text }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-container class="my-4">
        <v-row class="Justify-center align-center">
          <!-- Bild auf der linken Seite -->
          <v-col cols="12" md="6">
            <img src="/static/images/old_happy_people.webp" alt="Bild" width="100%"/>
          </v-col>

          <!-- Informationen auf der rechten Seite -->
          <v-col cols="12" md="6" >
            <h3 class="display-1 font-weight-bold primary--text">In 3 einfachen Schritten zu kostenlosen Pflegehilfsmitteln</h3>

            <v-list>
              <v-list-item>
                <v-list-item-icon class="justify-center text-center align-center">
                  <v-icon color="primary" x-large>mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <div>
                    <div class="title">Pflegehilfsmittel auswählen</div>
                    <p>Wählen Sie die passenden Produkte für sich aus</p>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="justify-center text-center align-center">
                  <v-icon color="primary" x-large>mdi-timer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <div>
                    <div class="title">Fragen beantworten</div>
                    <p>Dauert nicht länger als 5 Minuten <v-icon color="homeButton">mdi-lightning-bolt</v-icon></p>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="justify-center text-center align-center">
                  <v-icon color="primary" x-large>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <div>
                    <div class="title">Pflegehilfsmittel kostenlos erhalten</div>
                    <p>Erhalten Sie monatlich Ihre 100% kostenlosen Pflegehilfsmittel bequem nach Hause</p>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>


          <v-col cols="12" class="text-center">
            <v-btn
                class="white--text"
                color="homeButton"
                @click="openPflegeModal"
                :class="{'text-h5': $vuetify.breakpoint.mdAndUp}"
                x-large
            >
              Pflegehilfsmittel beantragen
            </v-btn>
          </v-col>
        </v-row>
    </v-container>

    <v-row class="text-center justify-center mb-4 mt-4">
      <v-col cols="12">
        <h2 class="display-1 font-weight-bold primary--text">Unsere Produktauswahl für Ihre Pflegehilfsmittel</h2>
        <p class="subtitle-1">Hochwertige Artikel für optimale Pflege.</p>
      </v-col>
    </v-row>

    <v-carousel cycle height="300px" hide-delimiter-background show-arrows-on-hover>
      <v-carousel-item
          v-for="(chunk, index) in chunkedProducts"
          :key="index"
      >
        <v-row>
          <v-col v-for="product in chunk" :key="product.id" cols="12" md="4">
            <v-card class="text-center no-border">
              <v-img :src="product.image" height="200px" :alt="product.title" contain class="grey lighten-2"></v-img>
              <v-card-title class="primary--text text-center d-flex align-center justify-center text-h5">
                {{ product.title }}
              </v-card-title>
              <v-card-subtitle class="text-h6">{{ product.manufacturer }}</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <div class="text-center mt-12">
      <v-btn
          color="homeButton"
          @click="openPflegeModal"
          :class="{'text-h5': $vuetify.breakpoint.mdAndUp}"
          class="white--text"
          x-large
      >
        Pflegehilfsmittel beantragen
      </v-btn>
    </div>

    <v-row class="text-center justify-center mb-4 mt-4">
      <v-col cols="12">
        <h2 class="display-1 font-weight-bold primary--text">Häufig gestellte Fragen (FAQs)</h2>
      </v-col>
    </v-row>

    <v-expansion-panels popout>
      <v-expansion-panel v-for="(item, i) in faqs" :key="i">
        <v-expansion-panel-header class="primary--text text-h5">{{ item.question }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-card-text class="text-h6">{{ item.answer }}</v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>



    <div id="exitModal" style="display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.7); z-index: 1000; align-items: center; justify-content: center;">
      <div style="width: 600px; padding: 20px; background: #fff; position: relative;">
        <span class="close-modal" @click="closeModal" style="font-size: 24px; color: #333; position: absolute; top: 10px; right: 10px; cursor: pointer;">×</span>


        <v-row>
          <v-col cols="8">
            <h2 style="text-align: center;">Sind Sie sich sicher, dass Sie keine kostenlosen Pflegehilfsmittel beantragen möchten?</h2>
            <p style="text-align: center;">Gesetzlicher Anspruch ab Pflegegrad 1</p>
            <p style="text-align: center; font-weight: bold;">Liegt ein Pflegegrad vor?</p>

            <v-row>
              <v-col cols="6">
                <v-btn @click="setCareDegreeAndNavigate" color="background" class="elevation-0" @mouseover="elevateButton" @mouseleave="deElevateButton" style="width: 100%; font-size: 20px; height: 60px;">
                  <div class="d-flex flex-column align-center justify-center">
                    <v-icon large color="white">mdi-thumb-up</v-icon>
                    <span class="white--text">Ja</span>
                  </div>
                </v-btn>
              </v-col>

              <v-col cols="6">
                <v-btn @click="closeAndToggle()" class="elevation-0" @mouseover="elevateButton" @mouseleave="deElevateButton" color="homeButton" style="width: 100%; font-size: 20px; height: 60px;">
                  <div class="d-flex flex-column align-center justify-center">
                    <v-icon large color="white">mdi-thumb-down</v-icon>
                    <span class="white--text">Nein</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>

          </v-col>

          <v-col cols="4" class="d-flex align-center justify-center">
            <img src="/static/images/old_chilling_guy.webp" alt="Bild" style="max-width: 100%;">
          </v-col>
        </v-row>
      </div>
    </div>

    <div id="pflegeModal" style="display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.7); z-index: 1000; align-items: center; justify-content: center;">
      <div style="width: 800px; padding: 20px; background: #fff; position: relative;">
        <span class="close-modal" @click="closeModal" style="font-size: 48px; color: #333; position: absolute; top: 5px; right: 0px; cursor: pointer;">×</span>

        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" class="d-flex flex-column justify-center">
            <h2 style="text-align: center;">Vielen Dank für Ihr <strong>Interesse</strong>.</h2>
            <p style="text-align: center;">
              <strong>Beantworten Sie die kommenden Fragen</strong> und sichern Sie sich Ihre <strong>Pflegehilfsmittel im Wert von 480€/Jahr</strong>.
            </p>

            <p style="text-align: center;">
              <v-icon>mdi-timer-sand</v-icon> Es dauert nicht länger als <strong>5 Minuten</strong>!
            </p>

            <p style="text-align: center;">
              <v-icon>mdi-check-circle-outline</v-icon> Schnell, einfach und effizient.
            </p>

            <p style="text-align: center;">
              <v-icon>mdi-cash-marker</v-icon> Kein Kostenrisiko.
            </p>

            <p style="text-align: center;">
              <v-icon>mdi-file-document-edit-outline</v-icon> Keine Bürokratie.
            </p>

            <h3 style="text-align: center;">Liegt Ihnen ein Pflegegrad vor?</h3>

            <v-row>
              <v-col cols="6">
                <v-btn @click="setCareDegreeAndNavigate" color="gradient-primary" class="elevation-0" @mouseover="elevateButton" @mouseleave="deElevateButton" style="width: 100%; font-size: 20px; height: 60px;">
                  <div class="d-flex flex-column align-center justify-center">
                    <v-icon large color="white">mdi-thumb-up</v-icon>
                    <span class="white--text">Ja</span>
                  </div>
                </v-btn>
              </v-col>

              <v-col cols="6">
                <v-btn @click="closeAndToggle()" class="elevation-0" @mouseover="elevateButton" @mouseleave="deElevateButton" color="gradient-primary" style="width: 100%; font-size: 20px; height: 60px;">
                  <div class="d-flex flex-column align-center justify-center">
                    <v-icon large color="white">mdi-thumb-down</v-icon>
                    <span class="white--text">Nein</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- Dieser Bereich wird bei kleinen Breakpoints ausgeblendet -->
          <v-col cols="4" class="d-flex align-center justify-center" v-if="!$vuetify.breakpoint.smAndDown">
            <img src="https://www.docdorado.de/media/ed/89/a3/1695931488/istockphoto-587508292-1024x1024.jpg" alt="Bild" style="max-width: 100%;">
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="showModal" persistent width="800">
      <v-card class="pa-6">
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-icon size="100" color="primary">mdi-information-box</v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
           <span class="mb-4">
              <v-card-title v-if="$vuetify.breakpoint.smAndDown" class="text-h4 font-weight-bold">
              Information
              </v-card-title>

              <v-card-title v-else class="text-h2 font-weight-bold">
                Information
              </v-card-title>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card-text class="text-h6 mb-6">
              Vielen Dank für Ihr Interesse.
              <br>
              Da Sie keinen Pflegegrad haben, können wir Ihnen aktuell leider kein Angebot machen.
              <br>
              Sollte sich dies ändern, können Sie gerne wieder auf uns zurückkommen.
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-actions class="justify-center py-6">
          <v-btn x-large color="primary" @click="showModal = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="welcomeModal" persistent width="800">
      <v-card class="pa-6">
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-icon size="100" color="primary">mdi-star-box</v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="mb-4">
              <v-card-title v-if="$vuetify.breakpoint.smAndDown" class="text-h4 font-weight-bold">
              Herzlich<br>Willkommen!
              </v-card-title>

              <v-card-title v-else class="text-h2 font-weight-bold">
                Herzlich Willkommen!
              </v-card-title>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card-text class="text-h6 mb-6">
              Wir stellen euch hier unseren <strong>vollautomatisierten Boxenkonfigurator</strong> vor, welchen wir auf
              euch <strong>individualisieren</strong> können. Ihr Patient beantwortet die Fragen, und anschließend holen
              wir für euch <strong>automatisiert die Genehmigung</strong> von der Kasse. Sobald diese vorliegt, starten
              wir ebenfalls <strong>automatisiert mit der Lieferung</strong>.
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-actions class="justify-center py-6">
          <v-btn x-large color="primary" @click="welcomeModal = false">
            Verstanden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defaultProducts } from "@/internaldata/DefaultProducts";



export default {
  name: 'MyComponent',

  data() {
    return {
      welcomeModal: false,
      modalShown: false,
      showModal: false,
      waitTimerActive: true,

      isMobile: window.innerWidth <= 768,

      products: defaultProducts,
      usps: [
        {icon: 'mdi-truck-fast', text: 'Gratis Versand', subtext: 'Innerhalb von Deutschland'},
        {icon: 'mdi-shield-check', text: 'Zuverlässige Lieferung', subtext: 'Ohne Unterbrechungen'},
        {icon: 'mdi-file-document-outline', text: 'Keine Bürokratie', subtext: 'Kein Papierkram'},
        {icon: 'mdi-cash-multiple', text: 'Ohne Zuzahlung', subtext: 'Volle Kostenübernahme'},
      ],
      faqs: [
        {
          question: "Was sind kostenlose Pflegehilfsmittel PG 54?",
          answer: "Pflegehilfsmittel PG 54 sind technische Hilfsmittel, die den Pflegealltag erleichtern und die körperliche Belastung für Pflegende und Pflegebedürftige reduzieren. Sie werden von der Pflegekasse kostenlos zur Verfügung gestellt, wenn sie zur Sicherung und Erleichterung der Pflege, zur Linderung der Beschwerden des Pflegebedürftigen oder zur selbstständigen Lebensführung benötigt werden."
        },
        {
          question: "Was sind Pflegehilfsmittel PG 51?",
          answer: "Pflegehilfsmittel PG 51 sind zum Verbrauch bestimmte Pflegehilfsmittel, wie zum Beispiel Bettschutzeinlagen, Einmalhandschuhe oder Desinfektionsmittel. Sie können monatlich bis zu einem bestimmten Betrag von der Pflegekasse erstattet oder direkt von einem Anbieter bezogen werden."
        },
        {
          question: "Wie beantrage ich Pflegehilfsmittel?",
          answer: "Um Pflegehilfsmittel zu beantragen, müssen Sie bei Ihrer Pflegekasse einen Antrag auf Kostenübernahme für die gewünschten Hilfsmittel stellen. Dies kann schriftlich oder in einigen Fällen auch online erfolgen. Nach Prüfung und Genehmigung werden die Hilfsmittel entweder direkt geliefert oder Sie erhalten eine Kostenübernahmeerklärung und können die Hilfsmittel selbst beschaffen."
        },
        {
          question: "Wer hat Anspruch auf kostenlose Pflegehilfsmittel?",
          answer: "Anspruch auf kostenlose Pflegehilfsmittel haben alle Personen mit einem anerkannten Pflegegrad, die zu Hause oder in einer Wohngemeinschaft gepflegt werden. Personen in stationären Pflegeeinrichtungen haben in der Regel keinen Anspruch auf diese Leistung."
        },
        {
          question: "Kann ich die Pflegehilfsmittel selbst auswählen?",
          answer: "Ja, grundsätzlich können Sie die Pflegehilfsmittel selbst auswählen. Es gibt jedoch bestimmte Vorgaben und Höchstgrenzen seitens der Pflegekassen. Es empfiehlt sich, vor der Beschaffung Rücksprache mit der Pflegekasse zu halten, um sicherzustellen, dass die gewählten Hilfsmittel auch erstattet werden."
        }
      ],
      testimonials: [
        {
          rating: 5,
          text: "Wir erhalten nun schon seit langem die Pflegehilfsmittel für unsere Oma und wir sind sehr zufrieden damit. Auch der Wechsel zu Pflegemittelhilfe war einfach und unkompliziert. Absolut empfehlenswert!"
        },
        {
          rating: 5,
          text: "Die Lieferung kommt stets pünktlich an und Anpassungen an den Pflegehilfsmitteln werden ohne Probleme vorgenommen. Großartige Arbeit, liebes Pflegemittelhilfe Team!!!"
        },
        {
          rating: 4,
          text: "Die Bestellung sowie die Lieferung der Hilfsmittel von Pflegemittelhilfe waren reibungslos. Der Kundendienst war bei allen Anliegen sehr hilfreich. Definitiv werde ich erneut bestellen."
        },
        {
          rating: 5,
          text: "Dank Pflegemittelhilfe wurde der Alltag meiner Oma erleichtert und wir konnten sie länger zuhause pflegen. Ein wahrer Segen für Familien."
        },
      ]
    };
  },

  mounted() {
    document.addEventListener('mouseleave', this.onMouseLeave);
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile();

    setTimeout(() => {
      this.waitTimerActive = false;
    }, 5000); // Setzt waitTimerActive nach 5 Sekunden auf false
  },




  methods: {
    setCareDegreeAndNavigate() {
      this.$store.commit('setCareDegree', 1);
      this.$router.push('/pflegegrad_bestimmen');
      this.$vuetify.goTo(0).then()
    },
    openPflegeModal() {
      document.getElementById('pflegeModal').style.display = 'flex';
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    closeAndToggle() {
      this.closeModal();
      this.showModal = true
    },
    showExitModal() {
      const exitModal = document.getElementById('exitModal');
      if (exitModal) {
        exitModal.style.display = 'flex';
      }
    },
    onMouseLeave(e) {
      if (!this.modalShown && !this.waitTimerActive) {
        if (e.clientY < 0) {
          this.showExitModal();
          this.modalShown = true;
        }
      }
    },


    closeModal() {
      document.getElementById('exitModal').style.display = 'none';
      document.getElementById('pflegeModal').style.display = 'none';
    },
    elevateButton(event) {
      event.currentTarget.classList.remove('elevation-0');
      event.currentTarget.classList.add('elevation-5'); // oder eine andere Zahl je nach gewünschter Schattenstärke
    },
    deElevateButton(event) {
      event.currentTarget.classList.remove('elevation-5');
      event.currentTarget.classList.add('elevation-0');
    },
  },

  computed: {
    iconSize() {
      if (this.$vuetify.breakpoint.xs) {
        return '48px';
      } else if (this.$vuetify.breakpoint.sm) {
        return '48px';
      } else if (this.$vuetify.breakpoint.md) {
        return '48px';
      } else {
        return '48px';
      }
    },

    imageSource() {
      if (this.$vuetify.breakpoint.md && !this.$vuetify.breakpoint.lg) {
        return '/static/images/old_chilling_guy.webp';
      } else {
        return 'static/images/adult.webp';
      }
    },

    calculatedAspectRatio() {
      if (this.$vuetify.breakpoint.md && !this.$vuetify.breakpoint.lg) {
        return 0.8; // oder ein anderes gewünschtes Verhältnis
      } else if (this.isMobile) {
        return 1;
      } else {
        return 0;
      }
    },

    filteredProducts() {
      return this.products.filter(product => !product.id.startsWith('G'));
    },

    chunkedProducts() {
      let i, j, chunk = 3;
      let chunkedArr = [];
      for (i = 0, j = this.filteredProducts.length; i < j; i += chunk) {
        chunkedArr.push(this.filteredProducts.slice(i, i + chunk));
      }
      return chunkedArr;
    }
  },

  beforeDestroy() {
    document.removeEventListener('mouseleave', this.onMouseLeave);
    window.removeEventListener('resize', this.checkMobile);
  },
}
</script>


<style scoped>
.square-card {
  aspect-ratio: 1/1;
}

</style>


