<template>
  <div class="container">

    <div class="primary--text text-h5 text-md-h3 font-weight-bold text-center  mt-4">
      Möchten Sie zusätzlich
      Bettschutzeinlagen für 0€ erhalten?
      <v-icon color="background">mdi-gift</v-icon>
    </div>

    <div class="image-container">
      <v-img
          src="/static/images/WVBettschutz.webp"
          contain
          height="300px"
          width="400px">
      </v-img>
    </div>

    <v-btn color="background" class="white--text mb-8" @click="selectBettschutz" x-large  :class="{'text-h5': $vuetify.breakpoint.mdAndUp}"  @mouseover="elevateButton"
           @mouseleave="deElevateButton" style="cursor: pointer">
      Kostenlosen Bettschutz
      <v-icon right>mdi-arrow-right</v-icon>
    </v-btn>

    <v-btn color="secondary" class="button secondary-button mb-4" x-large @click="$router.push('/dateneingabe')"  :class="{'text-h5': $vuetify.breakpoint.mdAndUp}"  @mouseover="elevateButton"
           @mouseleave="deElevateButton" style="cursor: pointer">
      Weiter ohne Bettschutz
    </v-btn>


    <p class="caption text-md-h6 text-center">
      Diese Bettschutzeinlagen können bis zu <strong>300</strong> Mal gewaschen werden und Sie können Bis zu 6 Stück pro Jahr geliefert werden. (jährliche Lieferung)
    </p>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  methods: {
    ...mapMutations(["increase"]),

    selectBettschutz() {
      this.increase('WB');
      this.$router.push('/dateneingabe');
      this.$vuetify.goTo(0).then()
    },

    elevateButton(event) {
      event.currentTarget.classList.remove('elevation-0');
      event.currentTarget.classList.add('elevation-5');
    },
    deElevateButton(event) {
      event.currentTarget.classList.remove('elevation-5');
      event.currentTarget.classList.add('elevation-0');
    },
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 72vh;
  padding: 50px 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60%;
  overflow: hidden;
}

.progress-bar {
  width: 99%; /* 2 von 5 Schritten -> 40% */
  height: 100%;
  background-color: var(--v-primary-base); /* Primärfarbe des Fortschrittsbalkens */
}


.button {
  font-size: 18px;
  border-radius: 5px;
  transition: all 0.3s;
}

@media (max-width: 900px) {

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    padding: 15px 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
  }
}

</style>
