import Vue from "vue";
import VueRouter from "vue-router";
import PreQuestion1 from "../views/PreQuestion1.vue";
import PreQuestion2 from "../views/PreQuestion2.vue";
import PreQuestion3 from "../views/PreQuestion3.vue";
import PreQuestion4 from "../views/PreQuestion4.vue";
import PreQuestion5 from "../views/PreQuestion5.vue";

import Step1Component from "../views/Step1Component.vue";
import Step2Component from "../views/Step2Component.vue";
import Step2ComponentLieferintervall from "../views/Step2ComponentLieferintervall.vue";
import Step2ComponentSign from "../views/Step2ComponentSign.vue";
import Step3Component from "../views/Step3Component.vue";
import Step4Component from "../views/Step4Component.vue";
import impressum from "../views/impressum.vue";
import datenschutz from "../views/Datenschutz.vue";
import agb from "@/views/agb.vue";


import Offline from "../views/Offline.vue";
import Router from "vue-router";
import ErrorPage from "@/views/ErrorPage.vue";

Vue.use(VueRouter);

const routes = [
    {path: '/pflegegrad', component: PreQuestion1},
    {path: '/pflegegrad_bestimmen', component: PreQuestion2},
    {path: '/versicherung', component: PreQuestion3},
    {path: '/pflegebox', component: PreQuestion4},
    {path: '/bettschutz', component: PreQuestion5},
    {path: '/impressum', component: impressum},
    {path: '/datenschutz', component: datenschutz},
    {path: '/agb', component: agb},

    {path: '/', redirect: {path: '/pflegegrad'}},
    {path: '/boxauswahl', component: Step1Component},
    {path: '/dateneingabe', component: Step2Component},
    {path: '/lieferintervall', component: Step2ComponentLieferintervall},
    {path: '/unterschrift', component: Step2ComponentSign},
    // {path: '/unterschrift', component: Step3Component},
    {path: '/unterschrift/:token', component: () => import("../views/Step3BComponent.vue")},
    {path: '/vielendank', component: Step4Component},
    {path: '/offline', component: Offline},
    {path: '/fehler', component: ErrorPage}
]

const router = new Router({
    routes
})

export default router