<template>
  <v-container>
    <div class="pt-4">
      <v-row justify="space-between">
        <v-col cols="auto mt-4">
          <v-btn :disabled="isLoading" to="/" text outlined x-large>Zurück</v-btn>
        </v-col>
      </v-row>

      <v-alert type="warning" v-if="!isOnline" class="mt-4">Sie sind noch Offline</v-alert>

      <v-row>
        <template v-for="(rs, i) in requestChain">
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <v-card>
              <v-card-subtitle>
                {{ getFullName(rs.customer.patientAddress) }}
              </v-card-subtitle>


              <v-card-text>
                <v-col class="py-0 px-0" cols="12"
                       v-for="(product, index) in rs.selectedProducts"
                       :key="index">
                  <v-icon>mdi-circle-small</v-icon>
                  <span class="font-weight-medium">{{ product.packagingUnit }} - {{ product.title }}</span>
                  {{ product.selectedVariant?.size }}
                  {{ product.selectedVariant?.material }}
                  </span>
                </v-col>
              </v-card-text>

              <v-card-actions>
                <v-btn text color="secondary" @click="removeElement(i)" v-if="false">
                  <v-icon>mdi-delete</v-icon>
                  Löschen
                </v-btn>
                <v-spacer/>
                <v-btn icon color="primary" @click="retrySendPostRequest(i)" :disabled="isLoading || !isOnline">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </v-card-actions>

            </v-card>
          </v-col>
        </template>
      </v-row>


    </div>
  </v-container>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {useOnline} from "@vueuse/core";
import {mapMutations} from "vuex";

export default {
  components: {},

  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    ...mapFields([
      'system.requestChain'
    ]),

    isOnline() {
      return useOnline()?.value
    }
  },
  methods: {

    removeElement(i){
      this.requestChain.splice(i, 1);
    },

    retrySendPostRequest(i) {
      const requestChainElement = this.requestChain[i];
      console.log(requestChainElement.customer.patientAddress.lastName)
      this.sendPostRequest(requestChainElement, i)
    },

    async sendPostRequest(output, i) {
      const url = 'https://bridge.pflegehilfsmittelparadies.de/api/register';
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(output),
        headers: {
          'Content-Type': 'application/json',
          'token': 'rr2geeghwlekhslkghelk'
        }
      }).then(() => {
        this.requestChain.splice(i, 1);
      })
          .catch()
          .finally(() => this.isLoading = false);
    },

    getFullName(patientAddress) {
      return [
        patientAddress.title,
        patientAddress.firstName,
        patientAddress.lastName
      ].join(' ')
    }

  },
};
</script>

<style scoped>

</style>
