<template>
  <div class="agb-container">
    <h1>Allgemeine Geschäftsbedingungen</h1>
    <h2>1. Allgemeines</h2><br>
    <p>Die Allgemeinen Geschäftsbedingungen der MedicalPro24 GmbH, 47119 Duisburg, Fon 0203
      72835533, E-Mail info@Pflegehilfsmittel24.shop (im Folgenden „Anbieter“), finden in ihrer
      derzeit gültigen Fassung Anwendung für alle Verträge zwischen dem Pflegebedürftigen und
      dem Anbieter für Pflegehilfsmittel, als Pflegehilfsmittelbox verkauft werden. Darüber hinaus
      finden die AGB Anwendung auf Leistungen, die im Zusammenhang mit dem Erwerb der
      Pflegemittelboxen stehen.</p>

    <h2>2. Gegenstand der Leistungen</h2><br>
    <p>Durch den Erwerb der Pflegemittelbox erhält der Pflegebedürftige wiederkehrend
      Verbrauchsprodukte, welche die häusliche Pflege erleichtern. Diese Pflegemittelbox sowie
      deren Zusendung sind für den Pflegebedürftigen kostenlos. Die Beantragung sowie die
      Abwicklung der Kostenübernahme erfolgt durch den Anbieter. Dies erfordert, dass der
      Pflegebedürftige seinen bestehenden Kostenerstattungsanspruch gegenüber der Pflegekasse
      an den Anbieter abtritt. Die zulässigen Pflegehilfsmittel zum Verbrauch werden von den
      Pflegekassen definiert. Zur Erbringung seiner Leistungen darf der Anbieter Dritte als
      Dienstleister beauftragen.</p>

    <h2>3. Pflegebedürftigkeit</h2><br>
    <p>Die Pflegebedürftigkeit einer Person wird in § 14 SGB XI definiert. Danach ist
      pflegebedürftig, wenn ein anerkannter Pflegegrad zwischen Pflegegrad 1 bis Pflegegrad 5
      vorhanden ist. Ein Anspruch auf Versorgung mit Verbrauchsprodukten, welche die häusliche
      Pflege erleichtern, liegt vor, wenn neben den weiteren Voraussetzungen des § 14 SGB XI die
      pflegebedürftige Person zuhause lebt, in einer Wohngemeinschaft lebt oder in einer
      Einrichtung für betreutes Wohnen lebt. Darüber hinaus muss der Pflegebedürftige zumindest
      von einer privaten Person (z.B. Angehöriger oder Nachbar) betreut werden.</p>

  <h2>4. Erstattungsanspruch</h2><br>
  <p>Liegen die Voraussetzungen der Pflegebedürftigkeit (Nr.3.) vor, hat der Pflegebedürftige einen
    monatlichen Erstattungsanspruch von bis zu 40,00 Euro durch seine Pflegekasse. Um die
    Erstattung zu erhalten, muss der Pflegebedürftige bei seiner Pflegekasse einen
    Kostenerstattungsantrag stellen. Beauftragt der Pflegebedürftige den Anbieter, erfolgt die
    Antragsstellung durch den Anbieter.</p>

  <h2>6. Bestellung</h2><br>
  <p>Durch seine Unterschrift auf dem Kostenübernahmeantrag erklärt der Pflegebedürftige bzw.
    dessen gesetzlicher Vertreter die Zustimmung und Bevollmächtigung des Anbieters zur
    Antragsstellung und -abwicklung der Pflegeleistungen im Namen des Pflegebedürftigen.
    Der Pflegebedürftige bzw. dessen gesetzlicher Vertreter müssen den Antrag online ausfüllen,
    signieren und den Antrag dann an den Anbieter absenden. Alternativ druckt der Pflegebedürftige
    bzw. der gesetzlicher Vertreter die ausgefüllten Formulare aus, unterzeichnet diese und sendet
    diese dann per Post an den Anbieter. Zunächst wählt der Pflegebedürftige oder sein gesetzlicher
    Vertreter auf pflegemittelbox.de die Pflegemittelbox aus, mit der er beliefert werden möchte. Nach
    Angaben der notwendigen Daten des Betreuers und der zu pflegenden Person ist der Antrag der
    Kostenübernahme auszufüllen. Ebenso hat vom Pflegebedürftigen oder seinem Bevollmächtigten
    die Beauftragung des Anbieters für die Beantragung und Abwicklung der Kostenübernahme durch
    die Pflegekasse zu erfolgen. Die Beauftragung umfasst auch die Abrechnung.
    Durch den Antrag auf Kostenübernahme, welchen der Anbieter für den Pflegebedürftigen stellt,
    wird Antrag auf Versorgung mit Pflegehilfsmittel gemäß seines Anspruchs für Pflegehilfsmittel
    durch den Pflegebedürftigen gestellt.
    Der Anbieter leitet den Antrag auf Kostenübernahme sodann an die zuständige Pflegekasse zur
    Prüfung weiter.</p>

  <h2>7. Auslieferung</h2><br>
  <p>Für den Fall der Kostenübernahmebestätigung liefert der Anbieter die bestelle Pflegemittelbox
    sofort kostenfrei aus.
    Die weitere Belieferung erfolgt monatlich oder in anderen vereinbarten Zeitabständen.
    Auch der Versand erfolgt kostenlos.</p>

  <h2>8. Abrechnung</h2><br>
  <p>Der Anbieter rechnet den abgetretenen Kostenerstattungsanspruch eigenständig mit der
    zuständigen Pflegekasse ab.</p>

  <h2>9. Erlöschen des Anspruchs auf Kostenübernahme der Pflegehilfsmittel</h2><br>
  <p>Liegen die Voraussetzungen für die Kostenübernahme der Pfleghilfsmittel nicht mehr vor, muss der
    Antragssteller den Anbieter darüber unverzüglich in Kenntnis setzen.
    In dem Fall hat der Anbieter das Recht zur außerordentlichen Kündigung.</p>

  <h2>10. Kündigung</h2><br>
  <p>Das Vertragsverhältnis für die Pflegemittelbox kann von beiden Seiten jederzeit mit einer Frist von
    14 Tagen zum Monatsende gekündigt werden. Überdies haben beide Parteien ein
    außerordentliches Kündigungsrecht.
    Kündigungen sind schriftlich per Mail, Fax oder Brief auszusprechen.</p>

  <h2>11. Nachlieferung bei Mängeln</h2><br>
  <p>Bei Mängeln der gelieferten Ware ist der Mangel unmittelbar nach Feststellung des Mangels
    gegenüber dem Anbieter anzuzeigen.
    Der Anbieter liefert dann innerhalb von 20 Tagen vom Zeitpunkt des Anzeigens des Mangels den
    Ersatz.
    </p>


  <h2>12. Widerrufsrecht</h2><br>
  <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
    widerrufen.
    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, <br><br>
    – an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz
    genommen haben bzw. hat, sofern Sie eine oder mehrere Waren im Rahmen einer einheitlichen
    Bestellung bestellt haben und diese einheitlich geliefert wird bzw. werden;<br>
    – an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die erste Ware in
    Besitz genommen haben bzw. hat, sofern im Rahmen einer Bestellung Waren zur regelmäßigen
    Lieferung über einen festgelegten Zeitraum hinweg geliefert werden.<br><br>
    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (MedicalPro24 GmbH), 47119 Duisburg, Fon
    0203 72835533, E-Mail info@Pflegehilfsmittel24.shop mittels einer eindeutigen Erklärung (z.B. ein
    mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
    widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden,
    das jedoch nicht vorgeschrieben ist.<br>
    Sie können das Muster-Widerrufsformular oder eine andere eindeutige Erklärung auch auf unserer
    Webseite (Pflegehilfsmittel24.shop) elektronisch ausfüllen und übermitteln. Machen Sie von dieser
    Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail) eine Bestätigung über
    den Eingang eines solchen Widerrufs übermitteln.<br><br>
    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
    Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.<br>
    Folgen des Widerrufs
    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten
    haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus
    ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste
    Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
    zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen
    ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
    Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;
    in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
    Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis
    Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches
    der frühere Zeitpunkt ist.<br><br>
    Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag,
    an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu
    übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen
    absenden.<br><br>
    Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
    Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf
    einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht
    notwendigen Umgang mit ihnen zurückzuführen ist.<br><br>
    Ausschluss- bzw. Erlöschensgründe
    Das Widerrufsrecht besteht nicht bei Verträgen<br>
    – zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle
    Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die
    persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind;<br>
    – zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell
    überschritten würde;<br>
    – zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber
    frühestens 30 Tage nach Vertragsschluss geliefert werden können und deren aktueller Wert von
    Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen Einfluss hat;<br>
    – zur Lieferung von Zeitungen, Zeitschriften oder Illustrierten mit Ausnahme von AbonnementVerträgen.
    Das Widerrufsrecht erlischt vorzeitig bei Verträgen<br>
    – zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene
    nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde;<br>
    – zur Lieferung von Waren, wenn diese nach der Lieferung aufgrund ihrer Beschaffenheit
    untrennbar mit anderen Gütern vermischt wurden;<br>
    – zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten
    Packung, wenn die Versiegelung nach der Lieferung entfernt wurde.
    Muster-Widerrufsformular
    (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie
    es zurück.)<br>
    – MedicalPro24 GmbH), 47119 Duisburg, Fon 0203 72835533, E-Mail
    info@Pflegehilfsmittel24.shop<br>
    – Hiermit widerrufe(n) ich/ wir (*) den von mir/ uns (*) abgeschlossenen Vertrag über den Kauf der
    folgenden Waren (*)/
    die Erbringung der folgenden Dienstleistung (*)<br>
    – Bestellt am (*)/ erhalten am (*)<br>
    – Name des/ der Verbraucher(s)<br>
    – Anschrift des/ der Verbraucher(s)<br>
    – Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf Papier)<br>
    – Datum<br>
    </p>

    <h2>13. Haftung des Anbieters</h2><br>
    <p>Der Anbieter haftet auf Schadenersatz nur bei Vorsatz, grober Fahrlässigkeit und leicht fahrlässiger
      Verletzung wesentlicher Vertragspflichten (Kardinalpflichten).
      Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des
      Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig
      vertraut und vertrauen darf.
      Die vorstehenden Haftungsbeschränkungen gelten nicht für schuldhaft verursachte Schäden aus
      der Verletzung des Lebens, des Körpers oder der Gesundheit.
      Die Vorschriften des Produkthaftungsgesetzes bleiben unberührt.
    </p>


</div>
</template>

<script>
export default {
  name: "agb",
  data() {
    return {
    };
  }
}
</script>

<style scoped>
.agb-container {
  font-family: Arial, sans-serif;
  max-width: 80%;
  margin: auto;
  padding: 20px;
}

.agb-section {
  margin-bottom: 20px;
}


.agb-section p {
  text-align: justify;
}
</style>
