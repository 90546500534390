<template>

  <div class="content-container">

    <!-- Fortschrittsbalken -->
    <div class="progress-container">
      <div class="progress-bar"></div>
    </div>

    <!-- Text -->
    <h2 class="primary--text mt-4 mb-4 align-center text-h4">Welchen Pflegegrad haben Sie?</h2>

    <v-slide-group
        mandatory
        show-arrows
    >
      <v-slide-item>
        <v-card
            rounded
            color="background"
            class="ma-4"
            height="200"
            :width="$vuetify.breakpoint.mdAndUp ? '200' : '130'"
            @click="handleClick(1)"
            @mouseover="elevateButton"
            @mouseleave="deElevateButton"
        >
          <v-row
              class="fill-height justify-center align-center text-center"
              no-gutters
          >
            <v-col>
              <img width="100" src="/static/icons/Pflegegrad1.png"
                   alt="Pflegegrad 1"/><br/>
              <span class="white--text pflegegrad">
                Pflegegrad 1
              </span>
            </v-col>

          </v-row>
        </v-card>
      </v-slide-item>

      <v-slide-item>
        <v-card
            rounded
            color="background"
            class="ma-4"
            height="200"
            :width="$vuetify.breakpoint.mdAndUp ? '200' : '130'"
            @click="handleClick(2)"
            @mouseover="elevateButton"
            @mouseleave="deElevateButton"
        >
          <v-row
              class="fill-height justify-center align-center text-center"
              no-gutters
          >
            <v-col>
              <img width="100" src="/static/icons/Pflegegrad2.png"
                   alt="Pflegegrad 2"/><br/>
              <span class="white--text pflegegrad">
                Pflegegrad 2
              </span>
            </v-col>

          </v-row>
        </v-card>
      </v-slide-item>

      <v-slide-item>
        <v-card
            rounded
            color="background"
            class="ma-4"
            height="200"
            :width="$vuetify.breakpoint.mdAndUp ? '200' : '130'"
            @click="handleClick(3)"
            @mouseover="elevateButton"
            @mouseleave="deElevateButton"
        >
          <v-row
              class="fill-height justify-center align-center text-center"
              no-gutters
          >
            <v-col>
              <img width="100" src="/static/icons/Pflegegrad3.png"
                   alt="Pflegegrad 3"/><br/>
              <span class="white--text pflegegrad">
                Pflegegrad 3
              </span>
            </v-col>

          </v-row>
        </v-card>
      </v-slide-item>

      <v-slide-item>
        <v-card
            rounded
            color="background"
            class="ma-4"
            height="200"
            :width="$vuetify.breakpoint.mdAndUp ? '200' : '130'"
            @click="handleClick(4)"
            @mouseover="elevateButton"
            @mouseleave="deElevateButton"
        >
          <v-row
              class="fill-height justify-center align-center text-center"
              no-gutters
          >
            <v-col>
              <img width="100" src="/static/icons/Pflegegrad4.png"
                   alt="Pflegegrad 4"/><br/>
              <span class="white--text pflegegrad">
                Pflegegrad 4
              </span>
            </v-col>

          </v-row>
        </v-card>
      </v-slide-item>

      <v-slide-item>
        <v-card
            rounded
            color="background"
            class="ma-4"
            height="200"
            :width="$vuetify.breakpoint.mdAndUp ? '200' : '130'"
            @click="handleClick(5)"
            @mouseover="elevateButton"
            @mouseleave="deElevateButton"
        >
          <v-row
              class="fill-height justify-center align-center text-center"
              no-gutters
          >
            <v-col>
              <img width="100px" src="/static/icons/Pflegegrad5.png"
                   alt="Pflegegrad 5"/><br/>
              <span class="white--text pflegegrad">
                Pflegegrad 5
              </span>
            </v-col>

          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <div v-observe-visibility="onVisibilityChanged"
         :class="['statistics-container', isMobile ? 'mobile-statistics' : '']">
      <div class="statistic-item">
        <v-icon color="primary" class="stat-icon">mdi-account-group</v-icon> <!-- Icon für Pflegebedürftige -->
        <p class="stat-number">{{ animatedNumber1 }}+</p>
        <p class="stat-label primary--text">Pflegebedürftige versorgt</p>
      </div>

      <div class="statistic-item">
        <v-icon color="primary" class="stat-icon">mdi-emoticon-happy</v-icon> <!-- Icon für Zufriedenheit -->
        <p class="stat-number ">{{ animatedNumber2 }}%</p>
        <p class="stat-label primary--text">Zufriedenheitsquote</p>
      </div>

      <div class="statistic-item">
        <v-icon color="primary" class="stat-icon">mdi-box-cutter</v-icon> <!-- Icon für Pflegeboxen -->
        <p class="stat-number ">{{ animatedNumber3 }}+</p>
        <p class="stat-label primary--text">Pflegehilfsmittel zugestellt</p>
      </div>
    </div>

  </div>
</template>

<script>

import {mapFields, mapMutations, mapState} from "vuex";


export default {
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      animatedNumber1: 0,
      animatedNumber2: 0,
      animatedNumber3: 0,
    };
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    elevateButton(event) {
      event.currentTarget.classList.remove('elevation-0');
      event.currentTarget.classList.add('elevation-5');
    },
    deElevateButton(event) {
      event.currentTarget.classList.remove('elevation-5');
      event.currentTarget.classList.add('elevation-0');
    },
    onVisibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.animateNumbers();
      }
    },
    animateNumbers() {
      let start1 = 0, end1 = 34000, duration1 = 2000;
      let start2 = 0, end2 = 98, duration2 = 2000;
      let start3 = 0, end3 = 270000, duration3 = 2000;

      let startTime = Date.now();

      const incrementNumber = () => {
        let elapsedTime = Date.now() - startTime;

        if (elapsedTime < duration1) {
          this.animatedNumber1 = Math.round(start1 + (end1 - start1) * (elapsedTime / duration1));
        }

        if (elapsedTime < duration2) {
          this.animatedNumber2 = Math.round(start2 + (end2 - start2) * (elapsedTime / duration2));
        }

        if (elapsedTime < duration3) {
          this.animatedNumber3 = Math.round(start3 + (end3 - start3) * (elapsedTime / duration3));
        }

        if (elapsedTime < Math.max(duration1, duration2, duration3)) {
          requestAnimationFrame(incrementNumber);
        }
      }

      incrementNumber();
    },


    handleClick(careDegree) {
      this.setCareDegree(careDegree);
      this.$router.push('/versicherung');
      this.$vuetify.goTo(0).then()
    },

    setCareDegree(careDegree) {
      this.$store.commit('SET_CARE_DEGREE', careDegree);
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile();
  },
  computed: {
    ...mapState({
      careDegree: state => state.customer.careDegree
    }),
  }
}
</script>


<style scoped>
.care-btn {
  margin: 0 10px;
  width: 14%; /* kleinere Button-Breite */
  height: 250px; /* kleinere Button-Höhe */
  font-size: 24px; /* kleinerer Schriftgrad */
}

.care-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icon-img {
  width: 60px; /* kleinere Bildgröße */
  height: 60px;
}

.gradient-primary {
  background: linear-gradient(135deg, #307470, #307470, #307470);
  border: none;
  padding: 10px 20px;
  color: #ffffff; /* Weißer Text, um auf dem Gradientenhintergrund hervorzustechen */
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}


.gradient-primary:hover {
  opacity: 0.9; /* Ein kleiner Hover-Effekt, um die Interaktion anzudeuten */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 73vh; /* Nehmen Sie die volle Höhe des Viewports */
  padding: 0 15px; /* Einige zusätzliche Polsterung an den Seiten, falls gewünscht */
}

.statistics-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-top: 50px; /* zusätzlicher Abstand zu den Buttons */
}

.pflegegrad{
  font-size: 25px;
}

.statistic-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stat-icon {
  font-size: 60px; /* Größere Icons */
  margin-bottom: 20px; /* Abstand zwischen Icon und Zahl */
}

.stat-number {
  font-size: 40px; /* Größere Zahl */
  font-weight: bold; /* Dickere Zahl */
  margin-bottom: 10px; /* Abstand zwischen Zahl und Beschreibung */
}

.stat-label {
  font-size: 18px;
  text-align: center;
}

.progress-container {
  width: 40%;
  height: 20px;
  background-color: #f0f0f0; /* Hintergrundfarbe des Fortschrittsbalkens */
}

.progress-bar {
  width: 40%; /* 2 von 5 Schritten -> 40% */
  height: 100%;
  background-color: var(--v-primary-base); /* Primärfarbe des Fortschrittsbalkens */
}

.button-container {
  flex-wrap: wrap; /* ermöglicht das Umbruchverhalten der Buttons */
}

@media (max-width: 900px) {
  .button-container > v-btn {
    width: calc(33.33% - 10px); /* -10px für einen kleinen Abstand */
    margin-right: 5px;
    margin-left: 5px;
  }

  .content-container {
    min-height: 80vh;
    padding: 15px 15px; /* Einige zusätzliche Polsterung an den Seiten, falls gewünscht */
  }

  .pflegegrad{
    font-size: 20px;
  }

  .button-container > v-btn:nth-child(4),
  .button-container > v-btn:nth-child(5) {
    width: calc(50%);
  }

  .mobile-statistics {
    flex-direction: column;
    align-items: center; /* Zentrieren Sie die Items für bessere Optik auf mobilen Geräten */
    gap: 20px; /* Einige Abstände zwischen den Items für bessere Optik auf mobilen Geräten */
  }

}

/* ... (der restliche Code bleibt unverändert) ... */

@media (min-width: 768px) and (max-width: 1024px) {
  /* Landscape-Tablets */
  .button-container > v-btn {
    width: calc(33.33% - 10px); /* Drei Buttons nebeneinander */
    margin-right: 5px;
    margin-left: 5px;
  }

  .button-container > v-btn:nth-child(4),
  .button-container > v-btn:nth-child(5) {
    width: calc(50% - 10px); /* Zwei Buttons nebeneinander */
  }

  /* Falls Sie auch das Layout für mobile Geräte anpassen möchten */
  .mobile-statistics {
    flex-direction: column;
    align-items: center; /* Zentrieren Sie die Items für bessere Optik auf Tablets */
    gap: 20px; /* Einige Abstände zwischen den Items für bessere Optik auf Tablets */
  }
}

/* ... (der restliche Code bleibt unverändert) ... */


</style>